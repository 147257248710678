<template>
  <div>
    <span>跟进记录</span>
    <div style="float: right">
      <a-button type="primary" size="small" @click="addModalVisible = true">添加跟进记录</a-button>
    </div>

    <AddFollowUpRecord v-model="addModalVisible" :item="item" @add="addRecord" @cancel="addModalVisible = false" />
  </div>
</template>

<script>
export default {
  components: {
    AddFollowUpRecord: () => import("./AddFollowUpRecord"),
  },
  props: ["item"],
  data() {
    return {
      addModalVisible: false,
    };
  },
  methods: {
    addRecord(itemRecord) {
      this.item.lead_follow_up_record_items.push(itemRecord);
      this.$emit("update", { ...this.item });
    },
  },
};
</script>

<style scoped></style>
